<template>
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6">
      <h1 class="font-bold text-xl mb-3 hidden lg:block">Dados comerciais</h1>
      <p class="text-gray-500">
        Precisamos de algumas informações a respeito do seu
        negócio para cumprir requisitos comerciais.
      </p>
    </header>
    <div class="space-y-5">
      <px-input
        mask="cnpj"
        placeholder="Somente números"
        name="cnpj"
        v-model="cnpj"
        :maxlength="cnpjRequiredLength"
        :invalidFeedback="invalidCNPJFeedback"
      >
        CNPJ
      </px-input>

      <px-input
        name="businessName"
        v-model="businessName"
      >
        Razão Social
      </px-input>

      <px-input
        name="companyName"
        v-model="companyName"
      >
        Nome da Loja
      </px-input>
      <px-select
        name="annualIncome"
        label="Faturamento anual"
        placeholder="Selecionar"
        :items="annualIncomeOptions"
        :value="annualIncome"
        @select="annualIncome = $event"
      />
      <px-input
        name="averageTicket"
        mask="currencyBRL"
        v-model="averageTicket"
      >
        Ticket médio
        <px-icon
          name="question-mark.svg"
          class="ml-1 cursor-pointer"
        />
      </px-input>
    </div>
    <footer class="flex justify-end space-x-3 mt-6">
      <px-button
        href="/onboarding/dados-cadastrais"
        class="w-full md:w-auto"
      >
        <ChevronLeftIcon slot="left" size="20"/>
        <span>Voltar</span>
      </px-button>
      <px-button
        kind="primary"
        class="w-full md:w-auto"
        @click="handleClick"
      >
        <span>Avançar</span>
        <ChevronRightIcon slot="right" size="20"/>
      </px-button>
    </footer>
  </template-onboarding>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { ChevronRightIcon, ChevronLeftIcon } from '@vue-hero-icons/solid';
import TemplateOnboarding from './TemplateOnboarding.vue';
import {
  PxButton,
  PxInput,
  PxIcon,
  PxSelect,
} from '../../../components/custom';

export default {
  name: 'CommercialData',
  metaInfo: {
    title: 'Dados comerciais',
  },
  components: {
    TemplateOnboarding,
    PxButton,
    PxInput,
    PxIcon,
    PxSelect,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  data: () => ({
    currentOnboardingStep: 1,
    cnpjRequiredLength: 14,
    cnpj: '',
    companyName: '',
    businessName: '',
    annualIncome: '',
    averageTicket: 0,
    siteURL: null,
    ecommercePlatform: '',
    invalidCNPJFeedback: null,
  }),
  computed: {
    ...mapState('onboarding', [
      'annualIncomeOptions',
      'siteProtocolOptions',
      'ecommercePlatformOptions',
      'onboardingSteps',
    ]),
    ...mapState('auth', ['uuid']),
    ...mapGetters('onboarding', ['businessData']),
    filteredPlatformOptions() {
      if (this.uuid) {
        return ['Nuvemshop'];
      }
      return this.ecommercePlatformOptions.filter((platform) => platform !== 'Nuvemshop');
    },
  },
  methods: {
    ...mapMutations('onboarding', ['setBusinessData']),
    ...mapActions('onboarding', ['saveInitialData']),
    getInvalidInputList() {
      const list = [];
      if (!this.cnpj || this.cnpj.length !== this.cnpjRequiredLength) list.push('cnpj');
      if (!this.companyName) list.push('companyName');
      if (!this.businessName) list.push('businessName');
      if (!this.annualIncome) list.push('annualIncome');
      if (!this.averageTicket) list.push('averageTicket');
      return list;
    },
    async handleClick() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.$refs.template.$emit('invalidInput', invalidInputList);
      } else {
        this.setBusinessData({
          cnpj: this.cnpj.slice(0, 14),
          companyName: this.companyName,
          businessName: this.businessName,
          annualIncome: this.annualIncome,
          averageTicket: Number.parseInt(this.averageTicket, 10) / 100,
        });
        const { errors } = await this.saveInitialData({ uuid: this.uuid });
        if (errors && errors.includes('CNPJ already registered')) {
          this.invalidCNPJFeedback = 'CNPJ já está cadastrado';
        }
      }
    },
  },
  mounted() {
    Object.entries(this.businessData).forEach(([key, value]) => {
      if (value) this[key] = value;
    });
  },
};
</script>
